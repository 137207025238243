<template>
  <div class="container-search">
    <div class="content">
      <ul class="tab">
        <li class="item" :class="[params.type == 1 ? 'item-active' : '']" @click="onTabChange(1)">
          案例
        </li>
        <li class="item" :class="[params.type == 2 ? 'item-active' : '']" @click="onTabChange(2)">
          教程
        </li>
        <li class="item" :class="[params.type == 3 ? 'item-active' : '']" @click="onTabChange(3)">
          文章
        </li>
      </ul>
      <div class="CaseListTab" v-show="params.type == 1">
        <div class="item" v-for="(item, index) in classificationData" :key="item.firstTypeId">
          <p class="label">{{ item.title }}</p>
          <div class="item-list" ref="classificationItem">
            <div class="tag tag-all" @click="onseletClassificationId(index, '')"
              :class="[classificationIdList[index] == '' ? 'tag-active' : '']">
              全部
            </div>
            <div class="tag" @click="onseletClassificationId(index, node.secondTypeId)"
              :class="[classificationIdList[index] == node.secondTypeId ? 'tag-active' : '']"
              v-for="(node, nodeIndex) in item.secondTypes" :key="nodeIndex">
              {{ node.title }}
            </div>
            <div class="folder" @click="onFold(index)" v-if="folderData[index] && folderData[index].show">
              <p>{{ folderData[index].fold ? '展开' : '收起' }}</p>
              <i :class="[folderData[index].fold ? 'el-icon-arrow-down' : 'el-icon-arrow-up']"></i>
            </div>
          </div>
        </div>
      </div>
      <CouresTab :ifAll="true" :typeId="typeId"  v-show="params.type == 2" @onTabChange="e => onTabChange(2, e)"></CouresTab>
      <CaseList v-loading="loading" v-if="params.type == 1" :caseList="list" :pagination.sync="pagination"></CaseList>
      <CourseList v-loading="loading" v-else-if="params.type == 2" :caseList="list" :pagination.sync="pagination">
      </CourseList>
      <CustomCaseList v-loading="loading" v-else :list="list" :pagination.sync="pagination"></CustomCaseList>
    </div>
  </div>
</template>
<script>
import CourseList from "@/components/course/courseList.vue";
import CaseList from "@/components/case/caseList.vue";
import CustomCaseList from "@/components/customCase/customCaseList";
import CouresTab from "@/views/course/couresTab.vue";
import { getSearchResult } from "@/api/search";
export default {
  name: "searchpage",
  components: {
    CourseList,
    CaseList,
    CustomCaseList,
    CouresTab
  },
  beforeRouteEnter(to, from, next) {

    next(vm => {
      vm.params.type = from.name == 'courseList' ? 2 :(from.name == 'classification' ? 1:(localStorage.getItem('searchType')||1))
      localStorage.setItem('searchType', vm.params.type)
      console.log('qwq',vm.params.type,from,from.name == 'courseList' ? 2 :(from.name == 'classification' ? 1:(localStorage.getItem('searchType')||1)))
      vm.isFist = false
      vm.classificationIdList = ['', '']
      vm.typeId=undefined
      vm.getList();
    })
  },
  activated() {
    this.classificationIdList = ['', '']
    console.log(this.params.type);
  },
  data() {
    return {
      typeId:undefined,
      classificationIdList: ['', ''],
      folderData: [],
      classificationData: [],
      loading: true,
      isFist: true,
      active: 1,
      list: [],
      params: {
        type: localStorage.getItem('searchType') || 1, //0案例1装修
        name: "", //模糊
      },
      pagination: {
        total: 0,
        current: 1,
        size: 10,
      },
    };
  },
  provide() {
    return {
      //这个api设定主动通知 将组件实例绑定在根组件上
      setChildrenRef: (name, ref) => {
        this[name] = ref;
      },
      //这个api是主动获取绑定的组件
      getChildrenRef: (name) => {
        return this[name];
      },
      // 这个是获取根组件
      getRef: () => {
        return this;
      },
    };
  },

  created() {
    this.getClassificationData()
    this.params.name = this.$store.state.search;
    // this.getList();
  },
  watch: {
    "pagination.current": {
      deep: true,
      handler(newVal, oldVal) {
        this.getList();
      },
    },
    "$store.state.search": {
      handler: function (newVal, oldVal) {
        this.params.name = this.$store.state.search.replace(/\s+/g, '');
        if (this.isFist) return
        this.pagination.current = 1;
        this.typeId=undefined
        this.getList();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    init() {
      this.params.name = this.$store.state.search;
      this.getList();
    },
    onseletClassificationId(index, id) {
      if (id == this.classificationIdList[index]) {
        this.classificationIdList[index] = ''
      } else {
        this.classificationIdList[index] = id
      }

      this.classificationIdList = this.classificationIdList.map(item => item)
      this.getList()
    },
    onFold(index) {
      if (this.folderData[index].fold) {
        this.$refs.classificationItem[index].style.height = 'auto'
      } else {
        this.$refs.classificationItem[index].style.height = '35px'
      }
      this.folderData[index].fold = !this.folderData[index].fold
      this.$forceUpdate()
    },
    async getClassificationData() {
      try {
        const res = await this.$http.get('/first-type/getAll')
        this.classificationData = res.data.filter(item => (item.title == '平台' || item.title == '行业'))

        // 设置展开收起
        this.$nextTick(() => {
          this.initFolder()
        })
      } catch (e) {
        console.log()
      }
    },
    //设置展开收起
    initFolder() {
      let folderData = []
      // console.log(this.$refs.classificationItem)
      this.$refs.classificationItem.forEach((item, index) => {
        if (item.offsetHeight > 35) {
          item.style.height = '35px'
          folderData.push({
            show: true,
            fold: true,
          })
        } else {
          folderData.push({
            show: false,
            fold: true,
          })
        }
      })
      this.folderData = folderData
    },
    onTabChange(type, id) {
      console.log(type, id)
      this.typeId=id
      this.pagination.current = 1;

      this.params.type = type;
      localStorage.setItem('searchType',type)
      this.getList(id);
    },

    async getList(id) {
      if (this.params.type == 2) {
        this.pagination.size = 12
      } else {
        this.pagination.size = 10
      }
      this.loading = true;
      this.list = []
      let typeId = this.typeId ? this.typeId : undefined
      let platform = this.params.type == 1 && this.classificationIdList[0] != '' ? this.classificationIdList[0] : undefined
      let industry = this.params.type == 1 && this.classificationIdList[1] != '' ? this.classificationIdList[1] : undefined
      const params = {
        ...this.params,
        ...this.pagination,
        typeId,
        platform,
        industry

      };
      try {
        const res = await getSearchResult(params);
        this.list = res.data.records;
        this.pagination.total = res.data.total;
        // this.$forceUpdate();
        this.loading = false;
      } catch (e) {
        this.$message.error(e.message);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-search {
  min-height: calc(100vh - 70px);
  padding-top: 30px;
  background: var(--BG);

  .content {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    .tab {
      display: flex;
      justify-content: center;

      .item {
        width: 60px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        margin-right: 20px;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }

      .item-active {
        background: var(--colorB);
        border-radius: 10px;
        color: #fff;
      }
    }
  }
}

.CaseListTab {

  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 10px;
  // height: 280px;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgb(229, 229, 229);
  box-shadow: 0px 4px 30px rgba(169, 169, 201, 0.25);
  backdrop-filter: blur(120px);
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 4px 30px rgba(169, 169, 201, 0.25);

  .tag-active {
    color: #fff !important;
    background: rgb(69, 79, 255);
    border-radius: 4px;
  }

  .item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    overflow: hidden;

    .label {
      width: 60px;
      height: 30px;
      color: #b5b5b5;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      margin-right: 15px;
      text-align-last: justify;
    }

    .button-all {
      width: 90px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      /* 主渐变色 */
      // border: 0px solid linear-gradient(rgba(69, 79, 255, 1) 0%, rgba(31, 209, 250, 1) 99.237%);
      border-radius: 4px;
      border: none;
      cursor: pointer;
      margin-right: 15px;

      &:hover {
        opacity: .8;
      }
    }

    .button-all-active {
      background: rgb(69, 79, 255);
      color: #fff;
    }


    .tag {
      width: 90px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(0, 0, 0);
      font-size: 14px;
      margin-right: 15px;
      cursor: pointer;
      margin-bottom: 5px;
      border-radius: 4px;
      // border: 1px solid rgba(#fff, 0);

      &:hover {
        color: var(--colorB);
        border: 1px solid var(--colorB);
        // background: #fff;
        transition: all .2s linear;
      }
    }

    .item-list {
      display: flex;
      align-items: center;
      flex: 1;
      flex-wrap: wrap;
      position: relative;
      transition: all .5s;

      .tag-all {
        border: none !important;
      }

      .folder {
        position: absolute;
        top: 0;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        cursor: pointer;
        color: var(--colorB);
        font-size: 14px;
        padding-bottom: 5px;

        &:hover {
          opacity: .6;
        }
      }


    }
  }
}
</style>
