<template>
  <div
    class="container-course-detail"
    :class="
      $store.state.identity === 2
        ? 'container-course-detail-vip'
        : 'container-course-detail-common'
    "
  >
    <div class="box-content">
      <div class="top">
        <div class="title">
          {{ detail.title }}
        </div>
        <div
          v-if="$store.state.identity !== 0"
          class="collection collection-active"
          @click="onCollection"
        >
          <i class="el-icon el-icon-star-off" v-if="!detail.collectionId"></i>
          <i class="el-icon el-icon-star-on" v-else style="color: #df583a"></i>
          <p>收藏</p>
        </div>
      </div>
      <div class="info">
        <div class="back" @click="onBack()">
          <i class="el-icon-back"></i>
          <p>返回教程</p>
        </div>
        <div class="data" v-if="$store.state.identity !== 0">
          <div class="eyes">
            <i class="el-icon el-icon-view"></i>
            <p class="text">{{ detail.browseNum ? detail.browseNum : 0 }}</p>
          </div>
          <div class="collection">
            <i class="el-icon el-icon-star-on"></i>
            <p class="text">
              {{ detail.collectionNum ? detail.collectionNum : 0 }}
            </p>
          </div>
        </div>
      </div>

      <!-- <div style="height: max-content;"  v-show="playerShow">
                <video id="player" playsinline controls>
                    <source
                        src="https://gm-case.oss-cn-hangzhou.aliyuncs.com/20241024/%E7%83%AD%E5%8C%BA%E8%A7%A6%E7%82%B9%E8%BD%AE%E6%92%AD-undefined.mp4"
                        type="video/mp4" />

                </video>
            </div>
            <div v-if="!playerShow" v-loading="!playerShow"></div> -->
      <div class="content">
        <div class="content-html" v-html="detail.text"></div>
      </div>
    </div>
    <div class="right">
      <div class="silder">
        <div class="title">
          <img src="../../assets/images/course/Calendar.png" alt="" />
          <p>快速导航</p>
        </div>

        <ul class="list">
          <li
            class="item"
            :class="item.decorateId === id ? 'item-active' : ''"
            v-for="item in relevantCourseList"
            :key="item.decorateId"
            @click="onViewRelevantCourse(item.decorateId)"
          >
            <p class="text">{{ item.title }}</p>
            <div class="view">
              <img src="../../assets/images/course/eye.png" alt="" />
              <p>{{ item.browseNum ? item.browseNum : 0 }}</p>
            </div>
          </li>
        </ul>
      </div>

      <div class="case">
        <div class="header">
          <div class="title">
            <img src="../../assets/images/course/Calendar.png" alt="" />
            <p>相关案例</p>
          </div>
          <div class="more" @click="viewMoreCase">
            <p>查看更多</p>
            <i class="el-icon-right"></i>
          </div>
        </div>

        <ul class="list" v-if="detail.cases && detail.cases[0]">
          <li class="item" v-for="node in detail.cases" :key="node.caseId">
            <div class="box-video">
              <video
                class="video"
                ref="video"
                :controls="node.play"
                controlslist="nodownload"
              >
                <source :src="node.videoUrl" type="video/mp4" />
              </video>
              <div class="play" v-if="!node.play">
                <img
                  @click="viewCase(node)"
                  src="../../assets/images/case/play.png"
                  width="40"
                  height="40"
                  alt=""
                />
              </div>
              <div
                style="
                  position: absolute;
                  width: 100px;
                  height: 100px;
                  background-color: #df583a;
                "
              ></div>
            </div>
            <div class="text">{{ node.name }}</div>
          </li>
        </ul>
        <el-empty v-else description="暂无数据！"></el-empty>
      </div>

      <div class="tip" v-if="$store.state.identity !== 0">
        <div class="item" @click="onUpgrade" v-if="$store.state.identity !== 2">
          <img src="../../assets/images/course/tip-c.png" alt="" />
        </div>
        <div class="item" @click="onConsult" v-else>
          <img src="../../assets/images/course/tip-v.png" alt="" />
        </div>
      </div>
    </div>
    <Upgrade
      :saveType="10"
      :saveCopyType="13"
      :visible.sync="visible"
    ></Upgrade>
    <CaseModelA
      v-if="caseModelAdialogVisible"
      :activedCaseDetail="activedCaseDetail"
      :caseModelAdialogVisible.sync="caseModelAdialogVisible"
    ></CaseModelA>
    <CaseModelB
      v-if="caseModelBdialogVisible"
      :activedCaseDetail="activedCaseDetail"
      :caseModelBdialogVisible.sync="caseModelBdialogVisible"
    ></CaseModelB>
    <DecorateDetail
      v-if="decorateDetailVisible"
      :visible.sync="decorateDetailVisible"
      :detail="activedCaseDetail"
    >
    </DecorateDetail>
  </div>
</template>

<script>
import { getCourseDetail } from "@/api/course";
import { saveBrowse, saveUserBrowseData } from "@/api/common";
import { getCaseInfo } from "@/api/case";
import Upgrade from "@/components/upgrade";
import CaseModelA from "@/components/case/caseModelA.vue";
import CaseModelB from "@/components/case/caseModelB.vue";
import DecorateDetail from "./decorateDetail.vue";
import Plyr from "plyr";

export default {
  name: "courseDetail",
  components: {
    Upgrade,
    CaseModelA,
    CaseModelB,
    DecorateDetail,
  },

  provide() {
    return {
      //这个api设定主动通知 将组件实例绑定在根组件上
      setChildrenRef: (name, ref) => {
        this[name] = ref;
      },
      //这个api是主动获取绑定的组件
      getChildrenRef: (name) => {
        return this[name];
      },
      // 这个是获取根组件
      getRef: () => {
        return this;
      },
    };
  },
  data() {
    return {
      html111: `"<div><p><br></p><div data-w-e-type="video" data-w-e-is-void="">
<video ="20240808="" 优加编辑器工具操作指南(新教程)-undefined.mp4"="" controls="true" width="auto" height="auto" id="player1"><source src="https://gm-case.oss-cn-hangzhou.aliyuncs.com/20240808/%E4%BC%98%E5%8A%A0%E7%BC%96%E8%BE%91%E5%99%A8%E5%B7%A5%E5%85%B7%E6%93%8D%E4%BD%9C%E6%8C%87%E5%8D%97(%E6%96%B0%E6%95%99%E7%A8%8B)-undefined.mp4" type="video/mp4"></video>
</div><p><img src="https://gm-case.oss-cn-hangzhou.aliyuncs.com/20240809/%E4%BC%98%E5%8A%A0%E7%BC%96%E8%BE%91%E5%99%A8%E6%93%8D%E4%BD%9C%E6%8C%87%E5%8D%97-undefined.png" alt="20240809/优加编辑器操作指南-undefined.png" data-href="" style=""></p></div>"`,
      relevantCourseList: [],
      id: null,
      typeId: null,
      detail: {},
      visible: false,
      activedCaseDetail: null,
      caseModelAdialogVisible: false,
      caseModelBdialogVisible: false,
      decorateDetailVisible: false,
      playerShow: false,
      options: {
        // 加载时自动播放媒体
        autoplay: true,
        // 时间轴段落标记
        markers: {
          enabled: true,
          points: [
            { time: 15, label: "段落标记1" },
            { time: 30, label: "段落标记2" },
          ],
        },
      },
    };
  },
  created() {
    this.init();
    console.log(111);
  },
  mounted() {},
  watch: {
    $route: {
      handler(newValue, oldValue) {
        this.init();
      },
      deep: true,
    },
  },
  methods: {
    updateVideoTags(htmlString) {
      // 创建一个 DOMParser 实例来解析 HTML 字符串
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");

      // 获取所有的 video 元素
      const videos = doc.getElementsByTagName("video");
      let videonum = 0;
      // 遍历 video 元素并更新 id 和移除 poster 属性
      for (let i = 0; i < videos.length; i++) {
        videonum = videonum + 1;
        // 设置新的 id 属性
        videos[i].id = `player${i + 1}`;
        // 移除 poster 属性
        videos[i].removeAttribute("poster");
      }

      // 创建一个临时的 div 元素来包裹我们要提取的内容
      const tempDiv = document.createElement("div");

      // 将 body 内的所有子节点移动到临时的 div 中
      while (doc.body.firstChild) {
        tempDiv.appendChild(doc.body.firstChild);
      }

      // 使用 innerHTML 获取临时的 div 中的 HTML 内容
      const modifiedHtmlString = tempDiv.innerHTML;
      return { text: `<div>${modifiedHtmlString}</div>`, num: videonum };
    },
    init() {
      if (this.$route.query.id) {
        this.id = this.$route.query.id;
        this.typeId = this.$route.query.typeId;
      }
      this.detail={}
      this.getCourseDetail();
      this.getRelevantCourse();
    },

    onConsult() {
      if (this.$store.state.identity !== 0) {
        saveUserBrowseData({
          typeId: 63,
        });
      }

      this.$store.commit("onlineConsult");
    },
    async viewCase(item) {
      try {
        const res = await getCaseInfo(item.caseId);
        this.activedCaseDetail = {
          ...item,
          ...res.data,
        };
        if (item.typeId === 1) {
          this.decorateDetailVisible = true;
        } else {
          this.caseModelAdialogVisible = true;
        }
      } catch (e) {
        // console.log(e)
      }

      // console.log(item, 'item')
    },

    onViewRelevantCourse(id) {
      this.id = id;
      this.onSaveBrowse();
      this.$router.push({
        name: "courseDetail",
        query: {
          id,
          typeId: this.typeId,
        },
      });
    },

    // 相关案例查看更多
    viewMoreCase() {
      this.$router.push({
        path: "/classification",
      });
    },

    // 获取相关内容
    async getRelevantCourse() {
      try {
        const res = await this.$http.get(`/decorate/getAll/${this.typeId}`);
        this.relevantCourseList = res.data;
      } catch (e) {}
    },

    onBack() {
      this.$router.back();
      // this.$router.push({ name: 'courseList' })
    },

    //保存浏览记录
    async onSaveBrowse() {
      try {
        const params = {
          type: 1,
          id: this.id,
        };
        const res = await saveBrowse(params);
        this.getRelevantCourse();
      } catch (e) {
        console.log(e);
      }
    },

    onCollection() {
      if (this.$store.state.identity === 0) {
        this.$store.commit("onShowLogindialog", true);
        return false;
      }

      if (this.detail.collectionId) {
        this.onCancelCollection();
      } else {
        this.onSaveCollection();
      }
    },

    //
    getList() {
      this.getCourseDetail();
    },
   timeToSeconds(timeStr) {  
    // 分割输入字符串，考虑可能存在的小时、分钟和秒  
    let parts = timeStr.split(':');  
  
    // 根据分割后的部分数量来判断时间格式  
    // 假设格式要么是 "M:S"（分钟:秒），要么是 "H:M:S"（小时:分钟:秒）  
    let seconds = 0;  
    if (parts.length === 2) {  
        // "M:S" 格式  
        let minutes = parseInt(parts[0], 10);  
        let secs = parseInt(parts[1], 10);  
        seconds = minutes * 60 + secs;  
    } else if (parts.length === 3) {  
        // "H:M:S" 格式  
        let hours = parseInt(parts[0], 10);  
        let minutes = parseInt(parts[1], 10);  
        let secs = parseInt(parts[2], 10);  
        seconds = hours * 3600 + minutes * 60 + secs;  
    } else {  
        // 如果格式不正确，可以抛出一个错误或者返回一个特定值  
      return 10000
    }  
  
    return seconds;  
} ,
    async getCourseDetail() {
      // try {
      const res = await getCourseDetail(this.id);
      // res.data.text = res.data.text.replace(new RegExp('poster', 'g'), '')

      const videoTags = this.updateVideoTags(res.data.text);
      res.data.text = videoTags.text;
      this.detail = res.data;
      let pointsList=res.data.pointsList?JSON.parse(res.data.pointsList):[];
      // let pointsList = [
      //   [
      //     { time: 15, label: "段落标记1" },
      //     { time: 30, label: "段落标记2" },
      //   ],
      //   [
      //     { time: 80, label: "段落标记1" },
      //     { time: 1000, label: "段落标记2" },
      //   ],
      // ];
      
      pointsList=pointsList.map(item=>{
        let arr=[]
        item= item.map(items=>{
          console.log(items)
          items.time=this.timeToSeconds(items.time)
          arr.push({...items,time:items.time+1})
          arr.push({...items,time:items.time-1})
          return items
        })
        return [...item,...arr]
      })
      console.log('pointsList',pointsList)
      for (let i = 0; i < videoTags.num; i++) {
        if(!(pointsList.length>=i+1)) return
        // 设置新的 id 属性
        this.$nextTick(() => {
          const player = new Plyr(`#player${i + 1}`, {
            autoplay: true,
            markers: {
              enabled: true,
              points: pointsList[i],
            },
            i18n: {
              speed: "速度",
              normal: "正常",
            },
          });
          this.playerShow = true;
          console.log(player);
        });
      }

      // } catch (e) {

      // }
    },
    // 收藏案例
    async onSaveCollection() {
      const data = {
        type: 1,
        id: this.detail.decorateId,
      };
      try {
        const res = await this.$http.post("/collection/collectionByType", data);
        this.getCourseDetail();
      } catch (e) {
        this.$message.error(e.message);
      }
    },

    // 取消收藏案例
    async onCancelCollection() {
      const data = {
        type: 1,
        id: this.detail.decorateId,
      };
      try {
        const res = await this.$http.post("/collection/removeCollection", data);
        this.getCourseDetail();
      } catch (e) {
        this.$message.error(e.message);
      }
    },
    // 升级年框
    onUpgrade() {
      this.visible = true;
      saveUserBrowseData({
        typeId: 7,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-course-detail-vip {
  .back {
    background: rgb(252, 246, 231);
  }
}

.container-course-detail-common {
  .back {
    background: rgb(235, 235, 235);
  }
}

.container-course-detail {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .content {
    flex: 1;
    padding: 30px 0;

    img {
      max-width: 100%;
    }

    video {
      max-width: 100%;
    }
  }
}

.box-content {
  flex: 1;
  padding: 20px 40px;
  background: #fff;
  border-radius: 10px;

  .info {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .back {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 30px;
      border-radius: 6px;
      color: rgba(0, 0, 0, 0.5);
      font-family: Noto Sans SC;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      p {
        margin-left: 4px;
      }
    }

    .data {
      display: flex;
      align-items: center;
      color: var(--colorA);
      font-size: 12px;

      .el-icon {
        font-size: 16px;
      }

      .eyes {
        width: 63px;
        display: flex;
        align-items: center;

        p {
          margin-left: 2px;
        }
      }

      .collection {
        width: 63px;
        display: flex;
        align-items: center;

        p {
          margin-left: 2px;
        }
      }
    }
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: rgb(0, 0, 0);
      font-size: 30px;
      font-weight: 400;
    }

    .collection {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 35px;
      border: 1px solid var(--colorD);
      background: #fff;
      color: var(--colorA);
      border-radius: 6px;
      cursor: pointer;

      p {
        line-height: 0;
      }

      &:hover {
        opacity: 0.8;
      }

      .el-icon {
        font-size: 20px;
      }
    }
  }
}

.right {
  width: 300px;
  margin-left: 40px;

  .case {
    margin-top: 10px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid rgb(244, 244, 244);

    .header {
      height: 50px;
      padding: 0 20px;
      border-bottom: 1px solid rgb(229, 229, 229);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .more {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;

        &:hover {
          opacity: 0.8;
        }

        p {
          margin-right: 2px;
        }

        // justify-content: center;
      }

      .title {
        display: flex;
        align-items: center;

        color: rgb(0, 0, 0);
        font-size: 14px;

        font-weight: 500;

        img {
          width: 24px;
          height: 26px;
          margin-right: 10px;
        }
      }
    }

    .list {
      display: grid;
      grid-template-columns: repeat(2, 110px);
      grid-row-gap: 15px;
      grid-column-gap: 25px;
      padding: 15px 26px;

      .item {
        .text {
          display: block;
          overflow: hidden; // 溢出隐藏
          text-overflow: ellipsis; // 溢出用省略号显示
          white-space: nowrap;
        }

        .box-video {
          width: 100%;
          height: 235px;
          border: 1px solid rgb(244, 244, 244);
          border-radius: 10px;
          overflow: hidden;
          position: relative;

          .video {
            width: 100%;
            height: 100%;
          }

          .play {
            position: absolute;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }

  .tip {
    margin-top: 20px;
    width: 100%;
    height: 150px;
    transition: all 0.5s;
    cursor: pointer;

    &:hover {
      transform: scale(1.02);
      // opacity: .6;
    }

    .item {
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.silder {
  height: 390px;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(244, 244, 244);

  .title {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid rgb(229, 229, 229);
    padding: 0 20px;
    color: rgb(0, 0, 0);
    font-size: 14px;

    font-weight: 500;

    img {
      width: 24px;
      height: 26px;
      margin-right: 10px;
    }
  }

  .list {
    padding: 15px;
    flex: 1;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
      height: 2px;
      scrollbar-arrow-color: #898989;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
      scrollbar-arrow-color: #898989;
    }

    /*滚动条里面轨道*/
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }

    .item-active {
      color: var(--colorB) !important;
      background: rgb(247, 247, 247);
      border-radius: 8px;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 35px;
      padding: 0 10px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.5);

      /* 浅浅灰 */
      &:hover {
        color: var(--colorB);
        background: rgb(247, 247, 247);
        border-radius: 8px;
      }

      .text {
        width: 70%;
        font-size: 14px;
        white-space: nowrap; //禁止换行
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .view {
        display: flex;
        align-items: center;

        img {
          width: 15.68px;
          height: 10px;
          margin-right: 4px;
        }

        p {
          color: rgba(0, 0, 0, 0.3);
          font-family: Noto Sans SC;
          font-size: 12px;
          line-height: 0;
        }
      }
    }
  }
}
</style>
