<template>
    <div>
        <ul class="tab" style="margin-bottom:20px;">
            <li class="item" v-for="(item, index) in typeList" :key="item.decorateTypeId"
                :class="[index === currentIndex ? 'item-active' : '']" @click="onTabChangeA(index)">
                <p>{{ item.platform }}</p>
            </li>
        </ul>
        <ul class="tab">
            <li class="item" v-for="item in typeList[currentIndex].children" :key="item.decorateTypeId"
                :class="[condition.id === item.decorateTypeId ? 'item-active' : '']"
                @click="onTabChange(item.decorateTypeId)">
                <p>{{ item.name }}</p>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    props: {
        ifAll: {
            type: Boolean,
            default: false
        },
        typeId: {
            type: String|Number,
            default: undefined
        },
    },
    watch: {
        typeId: {
            deep: true,
            handler(newVal, oldVal) {
                console.log(111, newVal)
                if (newVal == undefined) {
                    this.currentIndex = 0,
                        this.condition.id =''
                }
            },
        },
    },
    created() {
        this.getCourseType();
    },
    data() {
        return {
            currentIndex: 0,
            typeList: [],
            condition: {
                id: '',
            },
        }
    },
    methods: {
        onTabChangeA(index) {
            this.currentIndex = index
            this.onTabChange(this.typeList[this.currentIndex].children[0].decorateTypeId)
        },
        onTabChange(type) {
            this.condition.id = type
            this.$emit('onTabChange', type, this.condition)
        },
        // 获取装修教程类型
        async getCourseType() {
            try {
                const res = await this.$http.get("/decorate-type/getAll");



                console.log(res, "res")

                const platformMap = {};

                // 遍历原始数组，按照 platform 值将对象分组存储
                res.data.forEach((item) => {
                    const platform = item.platform;
                    if (!platformMap[platform]) {
                        platformMap[platform] = {
                            platform, // Store the platform string directly
                            children: [],
                        };
                    }
                    platformMap[platform].children.push(item);
                });

                // 最终的结果为一个对象数组，其中每个元素代表了一个共同的 "platform" 值下的对象集合，包含了 platform 和 children 数组。
                const result = Object.values(platformMap);


                this.typeList = result;

                if (this.ifAll) {
                    this.typeList = [{
                        platform: '全部', children: [{
                            decorateTypeId: '',
                            name: '全部',
                        }]
                    }, ...result]

                }
                this.condition.id = this.typeList[0].children[0].decorateTypeId;

                if (this.ifAll) return
                console.log(result, "result");

                this.$emit('getlist', this.condition)
            } catch (e) { }
        },

    },
}
</script>

<style lang="scss" scoped>
.tab {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .item {
        // width: 117px;
        padding: 0 10px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        // margin-right: 20px;
        cursor: pointer;
        transition: color 0.3s linear;

        &:hover {
            opacity: 0.7;
        }
    }

    .item-active {
        background: var(--colorB);
        border-radius: 10px;
        color: #fff;
    }
}
</style>