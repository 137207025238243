<template>
  <div class="container-course">
    <CouresTab @onTabChange="onTabChange" @getlist="getList"></CouresTab>
    <CourseList v-loading="loading"  :caseList="caseList" :pagination.sync="pagination"></CourseList>
  </div>
</template>
<script>
import { saveUserBrowseData } from "@/api/common";

import CourseList from "@/components/course/courseList.vue";
import CouresTab from "@/views/course/couresTab.vue";
export default {
  name: "courseList",
  components: {
    CourseList,
    CouresTab
  },
  data() {
    return {
      loading:true,
      active: 0,
      caseList: [],
      condition: {
        id: 0,
      },
      pagination: {
        total: 0,
        current: 1,
        size: 12,
      },
    };
  },

  provide() {
    return {
      //这个api设定主动通知 将组件实例绑定在根组件上
      setChildrenRef: (name, ref) => {
        this[name] = ref;
      },
      //这个api是主动获取绑定的组件
      getChildrenRef: (name) => {
        return this[name];
      },
      // 这个是获取根组件
      getRef: () => {
        return this;
      },
    };
  },
  watch: {
    "pagination.current": {
      deep: true,
      handler(newVal, oldVal) {
        this.getList();
      },
    },
  },
  created() {
    let loading=1
    console.log(loading)
    this.getList();
    saveUserBrowseData({
      typeId: 3,
    });
  },
  activated() {
    // this.getList()
  },
  methods: {
    onTabChange(type,condition) {
      this.condition=condition
      this.condition.id = type;
      this.pagination.current = 1;
      this.getList();
    },

    async getList(condition) {
      this.loading=true
      if(condition){
        this.condition=condition
      }
      const params = {
        ...this.condition,
        ...this.pagination,
      };
      try {
        const res = await this.$http.post("/decorate/getByType", params);
        this.caseList = res.data.records;
        this.pagination.total = res.data.total;
        this.loading=false
      } catch (e) { 
        this.loading=false
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container-course {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  // height: calc(100vh - 70px);
  // background: linear-gradient(rgba(47, 47, 255, 0.05) 0%, rgba(196, 196, 196, 0) 40%);

}
</style>