import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/styles/reset.css";
import '@/assets/styles/theme.css'
import axios from '@/service/axios'
import md5 from 'js-md5'
import ref from "vue-ref"
import moment from 'moment';
import VueAwesomeSwiper from 'vue-awesome-swiper/dist/vue-awesome-swiper'
import 'swiper/css/swiper.min.css'
import 'plyr/dist/plyr.css';
// import VuePlyr from 'vue-plyr'
// import 'vue-plyr/dist/vue-plyr.css'
Vue.use(VueAwesomeSwiper)
Vue.prototype.$md5 = md5
Vue.use(ref)
localStorage.setItem("search",'')
Vue.use(ElementUI);
Vue.prototype.$http = axios 
Vue.prototype.$moment = moment
Vue.config.productionTip = false  
// Vue.use(VuePlyr, {
//   plyr: {}
// })
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
