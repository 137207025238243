<template>
  <div id="app" @click="onClick">

      <router-view />

  </div>
</template>

<script>
import { addHeatMap } from "@/api/home";
// import html2canvas from "html2canvas"
export default {
  components: {},
  watch: {
    "$store.state.identity": {
      handler: function (newVal, oldVal) {
        document.documentElement.setAttribute(
          "identity",
          this.$store.state.identity
        );
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    document.documentElement.setAttribute(
      "identity",
      this.$store.state.identity
    );
    this.switchHeadIcon();
  },

  mounted() {
    // setTimeout(() => {
    // console.log(document.getElementsByTagName('iframe')[0].contentWindow.document.getElementsByClassName('chatcontainer'), 'chatIframe')
    // }, 2000)
  },

  data() {
    return {};
  },

  methods: {
    // saveImage() {
    //   // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
    //   html2canvas(document.body, {
    //     backgroundColor: null, //画出来的图片有白色的边框,不要可设置背景为透明色（null）
    //     useCORS: true, //支持图片跨域
    //     scale: 1, //设置放大的倍数
    //   }).then((canvas) => {
    //     // 把生成的base64位图片上传到服务器,生成在线图片地址
    //     let url = canvas.toDataURL("image/png"); // toDataURL: 图片格式转成 base64
    //     this.imgUrl = url;
    //     //将图片下载到本地
    //     let a = document.createElement("a"); // 生成一个a元素
    //     let event = new MouseEvent("click"); // 创建一个单击事件
    //     a.download = name || "截图名称"; // 设置图片名称没有设置则为默认
    //     a.href = this.imgUrl; // 将生成的URL设置为a.href属性
    //     a.dispatchEvent(event); // 触发a的单击事件
    //   });
    // },
    // 1920 - 1440

    onClick(e) {
      // this.saveImage()
      console.log(this.$route);
      if (this.$route.name === "home") {
        const clientWidth = document.body.clientWidth;
        var scrollTop =
          document.documentElement.scrollTop ||
          window.pageYOfset ||
          document.body.scrollTop;

        let x = 0;
        let y = 0;

        if (e.pageX || e.pageY) {
          x = e.pageX;
          y = e.pageY;
        } else if (e.clientX || e.clientY) {
          x =
            e.clientX +
            document.documentElement.scrollLeft +
            document.body.scrollLeft;
          y =
            e.clientY +
            document.documentElement.scrollTop +
            document.body.scrollTop;
        }

        const marginRate = (
          (1920 - 1440) /
          2 /
          ((document.body.clientWidth - 1440) / 2)
        ).toFixed(3);
        const margin = (clientWidth - 1440) / 2;
        const realMargin = (1920 - 1440) / 2;

        let convertedX = 0;
        let convertedY = y;

        // 点击的边缘
        if (x - margin < 0 || x > margin + 1440) {
          if (x > margin + 1440) {
            console.log("right");
            convertedX =
              (x - (1440 + margin)) * marginRate + (realMargin + 1440);
          } else {
            console.log("left");

            convertedX = x * marginRate;
          }
        } else {
          console.log("center");
          convertedX = x - margin + realMargin;
        }
        convertedX = parseFloat(convertedX.toFixed(2));
        addHeatMap({
          x: convertedX,
          y: convertedY,
          value: 1,
        });
        console.log(
          x,
          "x",
          y,
          "y",
          marginRate,
          "marginRate",
          convertedX,
          "convertedX",
          marginRate,
          "marginRate"
        );
      }
    },

    // 获取用户信息
    async getUserInfo() {
      try {
        const res = await this.$http.get("/user/getLoginUser");
        this.$store.commit("updateUserInfo", res.data);
      } catch (err) {
        console.log(err);
      }
    },
    // 切换图标
    switchHeadIcon() {
      let headHTML = document.getElementsByTagName("head")[0].innerHTML;
      let baseUrl = "<%= BASE_URL %>";
      headHTML += `<link rel="icon" href="${
        this.$store.state.identity !== 2 ? "ico-c.ico" : "ico-v.ico"
      }">`;
      document.getElementsByTagName("head")[0].innerHTML = headHTML;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Noto Sans SC;
  font-weight: 400;
  font-size: 14px;
  background: #fff;
  min-height: 100vh;
  min-width: 1200px;
}

nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.content-html {
  flex: 1;
  padding: 30px 0;

  p {
    font-size: 16px;
  }

  img {
    width: 100%;
    max-width: 100%;
  }

  video {
    width: 100%;
    max-width: 100%;
  }
}
</style>

